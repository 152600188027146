/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
        <div className="col-sm-6">
          <div className="mh-header-info">
            <div className="mh-promo wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
              <span>@jhonnyventiades</span>
            </div>

            <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Jhonny Ventiades Guillen</h2>
            <h5 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">[Software Engineer , Developer , Podcaster]</h5>

            <ul>
              <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                <i className="fa fa-envelope" />
                <a href="mailto:">jhonny.ventiadesg@gmail.com</a>
              </li>
            </ul>

            <ul className="social-icon wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
              <li><a target="blank" href="https://www.linkedin.com/in/jhonnyventiades"><i className="fa fa-linkedin" /></a></li>
              <li><a target="blank" href="https://medium.com/@jhonnyventiades"><i className="fa fa-medium" /></a></li>
              <li><a target="blank" href="https://twitter.com/JhonnyVentiades"><i className="fa fa-twitter" /></a></li>
              <li><a target="blank" href="https://github.com/jhonny-ventiades"><i className="fa fa-github" /></a></li>
              <li><a target="blank" href="https://www.facebook.com/jhonnyventiades"><i className="fa fa-facebook" /></a></li>
              <li><a target="blank" href="https://www.instagram.com/jhonnyventiades"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="hero-img wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
            <div className="img-border">
              <img src="../images/hero.png" alt="jhonn's profile" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
