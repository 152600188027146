/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';

// @ts-ignore
import Home from './Home.tsx';
// @ts-ignore
import Section from './Section.tsx';
// @ts-ignore
import Podcast from './Podcast.tsx';
// @ts-ignore
import Content from './Content.tsx';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Section sectionId="mh-home" backgroundImage="/images/home-bg-img.jpg">
          <Home />
        </Section>
        <Section sectionId="mh-podcast" backgroundImage="/images/podcast.png">
          <Podcast />
        </Section>
        <Section sectionId="mh-content">
          <Content />
        </Section>
      </>
    );
  }
}
