/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';

export default class Main extends Component<
  {children: any, sectionId: string, backgroundImage: string},
  {}> {
  sectionClasses = this.props.backgroundImage ? 'mh-home image-bg home-2-img' : 'mh-home';

  overlayClasses = this.props.backgroundImage ? 'img-foverlay img-color-overlay' : '';

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <section
        className={this.sectionClasses}
        id={this.props.sectionId}
        style={{ backgroundImage: `url(${this.props.backgroundImage})` }}
      >
        <div className={this.overlayClasses}>
          <div className="container">
            {
              this.props.children
            }
          </div>
        </div>
      </section>
    );
  }
}
