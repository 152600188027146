/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import Title from './Title.tsx';

export default function Content() {
  type ContentTypes = 'firebase' | 'android' | 'web' | 'codingTips';
  const navigate = useNavigate();
  const navTo = function navigateTo(type: ContentTypes) {
    navigate(`content/${type}`);
  };
  return (
    <div className="row section-separator jv-content">
      <Title titleKey="Content" subtitleKey="Content_subtitle" />
      <div className="col-sm-4" role="button" tabIndex={0} onClick={() => navTo('firebase')} onKeyUp={() => navTo('firebase')}>
        <div className="jv-content-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
          <img src="https://www.gstatic.com/mobilesdk/160503_mobilesdk/logo/2x/firebase_96dp.png" alt="Firebase" />
          <h2>Firebase</h2>
        </div>
      </div>
      <div className="col-sm-4" role="button" tabIndex={-1} onClick={() => navTo('android')} onKeyUp={() => navTo('android')}>
        <div className="jv-content-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <img src="https://cdn-icons-png.flaticon.com/512/5969/5969010.png" alt="Android" />
          <h2>Android</h2>
        </div>
      </div>
      <div className="col-sm-4" role="button" tabIndex={-2} onClick={() => navTo('web')} onKeyUp={() => navTo('web')}>
        <div className="jv-content-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <img src="https://cdn-icons-png.flaticon.com/512/876/876019.png" alt="Web" />
          <h2>Web</h2>
        </div>
      </div>
      <div className="col-sm-4" role="button" tabIndex={-3} onClick={() => navTo('codingTips')} onKeyUp={() => navTo('codingTips')}>
        <div className="jv-content-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <img src="https://cdn-icons-png.flaticon.com/512/1688/1688451.png" alt="Tips" />
          <h2>Coding Tips</h2>
        </div>
      </div>

    </div>
  );
}
