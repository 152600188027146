/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
// @ts-ignore
import Title from './Title.tsx';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="mh-footer mh-footer-3" id="mh-contact">
        <div className="container-fluid">
          <div className="row">
            <Title titleKey="Contact_me" subtitleKey="Contact_me_subtitle" />
            <div className="map-image image-bg col-sm-12">
              <div className="container mt-30">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="social-icon">
                      <li><a target="blank" href="https://www.linkedin.com/in/jhonnyventiades"><i className="fa fa-linkedin" /></a></li>
                      <li><a target="blank" href="https://medium.com/@jhonnyventiades"><i className="fa fa-medium" /></a></li>
                      <li><a target="blank" href="https://twitter.com/JhonnyVentiades"><i className="fa fa-twitter" /></a></li>
                      <li><a target="blank" href="https://github.com/jhonny-ventiades"><i className="fa fa-github" /></a></li>
                      <li><a target="blank" href="https://www.facebook.com/jhonnyventiades"><i className="fa fa-facebook" /></a></li>
                      <li><a target="blank" href="https://www.instagram.com/jhonnyventiades"><i className="fa fa-instagram" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
