/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  collection, query, orderBy, onSnapshot, where, Timestamp,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import db from '../firebase';
// @ts-ignore
import Title from './Title.tsx';
// @ts-ignore
// import ContentItem from './ContentItem.tsx';

export default function ContentList() {
  const params = useParams();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  useEffect(() => {
    const q = query(collection(db, 'content'), where(params.type, '==', true), orderBy('released', 'desc'));
    onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      setItems(data);
    });
  }, []);

  function getBadgeClassName(text: string): string {
    if (text === 'video') {
      return 'badge badge-danger';
    }
    if (text === 'talk') {
      return 'badge badge-primary';
    }
    return 'badge badge-success';
  }

  return (
    <div className="container new-page mb-5">
      <Title title={params.type} subtitleKey="Content_subtitle" />
      <div className="list-group list-group-flush mb-5">
        {
        items.map((item) => (
          <a
            href={item.link}
            target="_blank"
            className="list-group-item list-group-item-action list-group-item-dark"
            rel="noreferrer"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">{item.title}</h5>
              <small>
                {
                  (item.released instanceof Timestamp)
                    ? item.released.toDate().toLocaleString([], { dateStyle: 'medium' })
                    : new Date(item.released).toLocaleString([], { dateStyle: 'medium' })
                }
              </small>
            </div>
            <p className="mb-1">{item.description}</p>
            <span className={getBadgeClassName(item.type)}>{ t(item.type) }</span>
          </a>
        ))
        }
      </div>
    </div>
  );
}
