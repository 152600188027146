import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'es'];

const resources = {
  en: {
    translation: {
      Home: 'Home',
      Podcast: 'Podcast',
      Blog: 'Blog',
      Content: 'Content',
      Projects: 'Projects',
      Contact_me: 'Contact Me',
      My_Podcast: 'My Podcast',
      My_Podcast_subtitle: 'Listen and share :)',
      Content_subtitle: '[ Talks, Papers, Videos ]',
      Contact_me_subtitle: 'Send me a message through my networks:',
      video: 'Video',
      talk: 'Talk',
      paper: 'Paper',
    },
  },
  es: {
    translation: {
      Home: 'Inicio',
      Projects: 'Proyectos',
      Content: 'Contenido',
      Contact_me: 'Contáctame',
      My_Podcast: 'Mi Podcast',
      My_Podcast_subtitle: 'Escucha y comparte :)',
      Content_subtitle: '[ Charlas, Articulos, Videos ]',
      Contact_me_subtitle: 'Enviame un mensaje por mis redes:',
      talk: 'Charla',
      paper: 'Articulo',
    },
  },
};

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.
    detection: {
      checkWhitelist: true, // options for language detection
    },
    debug: false,
    whitelist: availableLanguages,
  });

export default i18n;
