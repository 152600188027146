import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyATLFn0c2lY0xeCNjqTI5W3fR-7tVgkkrg',
  authDomain: 'inmortal-deed2.firebaseapp.com',
  projectId: 'inmortal-deed2',
  storageBucket: 'inmortal-deed2.appspot.com',
  messagingSenderId: '1089253149389',
  appId: '1:1089253149389:web:6d341eaf398ef8a4a03784',
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;
