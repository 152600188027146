import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/App.css';
import './css/colors/blue-munsell.css';
import './css/responsive.css';
import './assets/icons/font-awesome-4.7.0/css/font-awesome.min.css';
import './assets/plugins/css/animate.css';
import './assets/plugins/css/owl.css';
import './assets/plugins/css/jquery.fancybox.min.css';

// @ts-ignore
import Header from './components/Header.tsx';
// @ts-ignore
import Footer from './components/Footer.tsx';
// @ts-ignore
import Main from './components/Main.tsx';
// @ts-ignore
import ContentList from './components/ContentList.tsx';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/content/:type" element={<ContentList />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
