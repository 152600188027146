/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
// @ts-ignore
import Title from './Title.tsx';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row section-separator">
        <div className="col-sm-12 col-md-12">
          <div className="mh-about-inner">
            <Title titleKey="My_Podcast" subtitleKey="My_Podcast_subtitle" />
            <h3><a href="https://anchor.fm/jhonnyvg/" target="_blank" rel="noreferrer">Declarando Variables</a></h3>
            <p className="wow fadeInUp sp" data-wow-duration="0.8s" data-wow-delay="0.2s">
              <iframe
                title="Podcast Declarando Variables"
                src="https://anchor.fm/jhonnyvg/embed"
                className="podcast-frame"
                frameBorder="0"
                scrolling="no"
              />
            </p>
            <ul className="social-icon wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
              <li><a target="blank" href="https://open.spotify.com/show/0MvEcUaGszWx2BwgkAkZiE"><i className="fa fa-spotify" /></a></li>
              <li><a target="blank" href="https://podcasts.apple.com/us/podcast/declarando-variables/id1524236639?uo=4"><i className="fa fa-apple" /></a></li>
              <li><a target="blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvNDQ4ODU0OS9lcGlzb2Rlcy9mZWVk"><i className="fa fa-google" /></a></li>
              <li><a target="blank" href="https://music.amazon.com/podcasts/a5188873-b712-41f0-b9a6-163a3845e285"><i className="fa fa-amazon" /></a></li>
              <li><a target="blank" href="https://anchor.fm/s/2bd61afc/podcast/rss"><i className="fa fa-rss" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
