/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Header extends Component<{t, i18n}, {language: string}> {
  constructor(props) {
    super(props);
    this.state = { language: this.props.i18n.language };
  }

  changeLanguageHandler = (language: string) => {
    this.props.i18n.changeLanguage(language);
    this.setState({ language });
  };

  getLanguageButton = (language: string) => {
    let languageToAssing: string;
    let imgComponent: any;

    if (language === 'es') {
      languageToAssing = 'en';
      imgComponent = <img alt="English translation" src="/images/United-Kingdom.png" />;
    }
    if (language === 'en' || language === 'en-US') {
      languageToAssing = 'es';
      imgComponent = <img alt="Spanish translation" src="/images/Spain.png" />;
    }
    return (
      <button
        type="button"
        className="img-responsive center-block  en"
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => this.changeLanguageHandler(languageToAssing)}
      >
        {
        imgComponent
      }
      </button>
    );
  };

  // eslint-disable-next-line require-jsdoc
  render() {
    const { t } = this.props;
    return (
      <header className="black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav" id="mh-header">
        <nav className="navbar fixed-top navbar-expand-lg mh-nav nav-btn">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="/#mh-home">{t('Home')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#mh-podcast">{t('Podcast')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="/#mh-content">{t('Content')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="/#mh-contact">{t('Contact_me')}</a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  {
                    this.getLanguageButton(this.state.language)
                }
                </a>
              </li>

            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
export default withTranslation()(Header);
