/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Title extends Component<{
  t,
  i18n,
  titleKey: string,
  subtitleKey: string,
  title: string}, {}> {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
        <h1>{this.props.title ? this.props.title.toUpperCase() : t(this.props.titleKey)}</h1>
        <h6>{t(this.props.subtitleKey)}</h6>
        <br />
        <br />
      </div>
    );
  }
}
export default withTranslation()(Title);
